import React, { FunctionComponent } from 'react';
import GlobalStateContext from '../contexts/GlobalStateContext';
import GlobalDispatchContext from '../contexts/GlobalDispatchContext';
import { AppState, AppStateReducer } from '../types/contexts/AppContext';

const initialState: AppState = {
  todo: [],
  accessToken: '',
  notifications: [],
};

const reducer: AppStateReducer = (prevState, action) => {
  switch (action.type) {
    case 'addTaskAction':
      return { ...prevState, todo: [action.payload, ...prevState.todo] };
    case 'AddAccessTokenAction':
      return { ...prevState, accessToken: action.payload };
    case 'AddNotificationAction':
      return {
        ...prevState,
        notifications: [...prevState.notifications, action.payload],
      };
    case 'DeleteNotificationAction': {
      const notications = prevState.notifications;
      const index = notications.indexOf(action.payload);
      if (index === -1) return { ...prevState };

      notications.splice(index, 1);
      return {
        ...prevState,
        notifications: notications,
      };
    }
    default:
      return { ...prevState };
  }
};

const AppContextProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = React.useReducer<AppStateReducer>(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default AppContextProvider;
