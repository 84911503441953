import React from 'react';

const StyleSample = () => {
  return (
    <div className="Home container">
      <h1>H1コンテンツ</h1>
      <h2>H2コンテンツ</h2>
      <h3>H3コンテンツ</h3>
      <h4>H4コンテンツ</h4>
      <p>Pタグ</p>
      <table className="table table-border">
        <thead>
          <tr>
            <th className="primary">見出し</th>
            <th className="success">見出し</th>
            <th className="info">見出し</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>内容</td>
            <td>内容</td>
            <td>内容</td>
          </tr>
          <tr>
            <td>内容</td>
            <td>内容</td>
            <td>内容</td>
          </tr>
          <tr>
            <td>内容</td>
            <td>内容</td>
            <td>内容</td>
          </tr>
        </tbody>
      </table>
      <ul>
        <li>リスト</li>
        <li>リスト</li>
        <li>リスト</li>
        <li>リスト</li>
      </ul>
      <ol>
        <li>リスト</li>
        <li>リスト</li>
        <li>リスト</li>
        <li>リスト</li>
      </ol>
      <a href="#" className="mr-1">
        リンク
      </a>
      <a href="#" className="btn mr-1">
        リンク
      </a>
      <a href="#" className="btn btn-primary mr-1">
        リンク
      </a>
      <a href="#" className="btn btn-success mr-1">
        リンク
      </a>
      <a href="#" className="btn btn-info mr-1">
        リンク
      </a>
      <a href="#" className="btn btn-danger">
        リンク
      </a>
      <form>
        <div className="form-inline">
          <label>ラベル</label>
          <input type="text" />
        </div>
        <div className="form-inline">
          <label>ラベル</label>
          <input type="text" />
        </div>
        <div className="form-inline">
          <label>ラベル</label>
          <input type="text" />
        </div>
        <div className="form-inline">
          <label>ラベル</label>
          <input type="text" />
        </div>
        <div className="form-inline">
          <label>ラベル</label>
          <input type="text" />
        </div>
      </form>
    </div>
  );
};

export default StyleSample;
