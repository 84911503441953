import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Table from '../../../shared/Tables';
import httpClient from '../../../../libs/HTTPClient';
import Logger from '../../../../libs/Logger';
import useAppStateDispatch from '../../../../hooks/useAppStateDispatch';
import HygiError from '../../../../types/HygiError';
import './Contracts.scss';
import dayjs from 'dayjs';

type ContractType = {
  companyId: string | JSX.Element;
  name: string | JSX.Element;
  billingCount: string | JSX.Element;
  amount: string | JSX.Element;
  freeeCompanyName: string | JSX.Element;
};

const Contracts: FunctionComponent = () => {
  const date: any = dayjs().add(-1, 'month');
  const [showContracts, setShowContracts] = useState(false);
  const [contracts, setContracts] = useState<ContractType[]>([]);
  const dispatch = useAppStateDispatch();

  useEffect(() => {
    showConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showContracts && getContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContracts]);

  const addNotificationErrors = useCallback(
    (errors: HygiError[]): void => {
      errors.forEach((error: HygiError): void => {
        dispatch({
          type: 'AddNotificationAction',
          payload: { type: 'error', message: error.detail },
        });
      });
    },
    [dispatch]
  );

  const accessTokenCheck = async (): Promise<boolean> => {
    const resp = await httpClient.post('/freees/access_token_check', { state: 'contracts' });
    if (resp.status !== 201) {
      addNotificationErrors([
        {
          title: 'Access Tokenの取得に失敗しました。',
          detail: 'Access Tokenの取得に失敗しました。',
          status: 500,
        },
      ]);
      return false;
    } else if (resp.data?.redirectUri) {
      window.location.href = resp.data.redirectUri;
      return false;
    }
    return true;
  };

  const showConfirmation = async (): Promise<void> => {
    const resp = await httpClient.get(`/partner_logs/need_update`, {
      query: { year: date.year(), month: date.month() },
    });
    if (resp.status !== 200) {
      Logger.error(resp.data.errors);
      addNotificationErrors(resp.data.errors);
    } else {
      setShowContracts(!resp.data);
    }
  };

  const contractHeader = {
    companyId: '会社ID',
    companyName: '会社名',
    billingCount: '対象人数',
    amount: '請求金額',
    freeeCompanyName: 'freee上の取引先名',
  };

  const getContracts = async (): Promise<void> => {
    const result = await accessTokenCheck();
    if (!result) return;

    const resp = await httpClient.get(`/contracts`);
    if (resp.status !== 200) {
      Logger.error(resp.data.errors);
    } else {
      setContracts(resp.data);
    }
  };

  const formatContracts = (): ContractType[] => {
    return contracts.map(obj => {
      return {
        companyId: obj.companyId,
        name: obj.name,
        billingCount: obj.billingCount,
        amount: obj.amount,
        freeeCompanyName:
          `${obj.name}_ハイジ` === obj.freeeCompanyName ? (
            obj.freeeCompanyName
          ) : (
            <span className="text-danger">
              {!obj.freeeCompanyName
                ? 'freeeに登録されていません。取引先取込処理を行ってください'
                : 'ハイジとfreeeで会社名が一致していません。'}
            </span>
          ),
        _className: obj.freeeCompanyName ? '' : 'not-aplicable',
      };
    });
  };

  const createInvoices = async (): Promise<void> => {
    const result = await accessTokenCheck();
    if (!result) return;

    const resp = await httpClient.post(`/contracts/create`);
    if (resp.status !== 201) {
      Logger.error(resp.data.errors);
      addNotificationErrors(resp.data.errors);
    } else {
      dispatch({
        type: 'AddNotificationAction',
        payload: { type: 'info', message: 'Invoicesが成功に作られました' },
      });
      window.location.reload();
    }
  };

  return (
    <div className="container">
      <h1>請求情報取込</h1>
      <p>
        {date.format('YYYY年M月')}
        月の請求情報の取込を行います。freeeに取り込み済みの取引先が対象となります。
      </p>
      {showContracts ? (
        <div>
          <div className="row mt-5">
            <div className="col-2 center-y">
              <button
                className="btn btn-primary large fullwidth"
                onClick={(): void => {
                  createInvoices();
                }}
              >
                請求取込実行
              </button>
            </div>
          </div>
          <Table
            class="table-xborder fullwidth center-table"
            header={contractHeader}
            data={formatContracts()}
          />
        </div>
      ) : (
        <p className="m-10 text-danger">
          未取込の取引先があります。「取引先情報確認・取込」画面から取込処理を実行してください
        </p>
      )}
    </div>
  );
};

export default Contracts;
