import React, { FunctionComponent } from 'react';

type TableProps = {
  header?: Record<string, string>;
  data: Record<string, string | JSX.Element>[];
  class?: string;
};

const Table: FunctionComponent<TableProps> = props => {
  return (
    <table className={props.class}>
      {props.header && (
        <thead>
          <TableRow key="header" {...props.header} />
        </thead>
      )}
      <tbody>
        {props.data.map((row, index) => (
          <TableRow key={index} {...row} />
        ))}
      </tbody>
    </table>
  );
};

type TableRowType = Record<string, string | JSX.Element> | { [key: string]: number };
const TableRow: FunctionComponent<TableRowType> = data => {
  return (
    <tr className={data._className as string}>
      {Object.entries(data).map(
        ([key, value]) =>
          !key.startsWith('_') && (
            <th key={key} className={key}>
              {value}
            </th>
          )
      )}
    </tr>
  );
};

export default Table;
