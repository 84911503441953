import React, { FunctionComponent, useCallback, useEffect } from 'react';
import RouterWrapper, { Props as RouterWrapperProps } from './RouteWrapper';
import { RouteProps } from 'react-router-dom';
import HTTPClient from '../libs/HTTPClient';
import useAccessToken from '../hooks/useAccessToken';

const RouteWithAccessTokenCheck: FunctionComponent<RouterWrapperProps & RouteProps> = props => {
  const query: Array<string> | undefined = props.location?.search.slice(1).split('&');
  const params = query?.reduce((obj: Record<string, string>, param) => {
    const [key, value] = param.split('=');
    obj[key] = value;
    return obj;
  }, {});

  // access_tokenがない場合は、railsのログイン画面にリダイレクト
  if (!params?.access_token && !localStorage.getItem('AccessToken')) {
    window.location.href = `${process.env.REACT_APP_RAILS_ADMIN_URL}`;
  }

  const accessToken = params?.access_token;
  const access = useAccessToken();

  const getToken = useCallback(() => {
    if (accessToken) {
      localStorage.setItem('AccessToken', accessToken);
      // URLにアクセストークンがある場合は、パラメータなしのURLにリダイレクト
      window.location.replace(props.location?.pathname as string);
    }
  }, [accessToken, props.location]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  // Use AccessToken with
  const token = accessToken || access || localStorage.getItem('AccessToken');
  if (token) {
    HTTPClient.config.headers.Authorization = `Bearer ${token}`;
  }

  return <RouterWrapper {...props} />;
};

export default RouteWithAccessTokenCheck;
