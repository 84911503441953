import React, { FunctionComponent, useEffect } from 'react';
import useAppStateDispatch from '../../hooks/useAppStateDispatch';
import NotificationType from '../../types/contexts/Notification';

// Time until the notification fades out in seconds
const TIMEOUT = 5;

type NotificationsProp = {
  notification: NotificationType;
};

const Notification: FunctionComponent<NotificationsProp> = props => {
  const dispatch = useAppStateDispatch();

  const remove = (): void => {
    dispatch({ type: 'DeleteNotificationAction', payload: props.notification });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      remove();
    }, TIMEOUT * 1000);
    return (): void => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      <div className={`notification  ${props.notification.type}`}>
        {props.notification.message}
        <button onClick={remove} className="closeNotification" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  );
};

type NotificationsProps = {
  notifications: NotificationType[];
};

const Notifications: FunctionComponent<NotificationsProps> = props => {
  return (
    <div>
      {props.notifications?.map((notification, index) => {
        return <Notification key={index} notification={notification} />;
      })}
    </div>
  );
};

export default Notifications;
