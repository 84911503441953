import winston, { LogEntry } from 'winston';
import dayjs from 'dayjs';
import { ErrorLogMethod, LogMethod } from '../types/libs/Logger';

let defaultMeta: Record<string, any> = {};

const _timestampFormat = (): string => dayjs().format('YYYY-MM-DDTHH:mm:ssZ');

const passwordKeyRegex = /password/i;
const recursiveFilteringPassword = (obj: LogEntry): LogEntry => {
  if (!obj) {
    return obj;
  }

  Object.getOwnPropertyNames(obj).forEach(key => {
    if (passwordKeyRegex.test(key)) {
      obj[key] = '[filtered]';
      return;
    }
    if (typeof obj[key] === 'object') {
      obj[key] = recursiveFilteringPassword(obj[key]);
    }
  });

  return obj;
};
const filteringPassword = winston.format(info => recursiveFilteringPassword(info));

const labeling = winston.format(info => {
  const isArray = Array.isArray(info.label);
  if (typeof info.label !== 'string' && !isArray) {
    return info;
  }

  const labels: string[] = isArray ? info.label : [info.label];
  delete info.label;

  const message = info.message || '';
  const label = labels.map(label => `[${label}]`).join('');
  info.message = `${label} ${message}`;

  return info;
});

let silent = false;
let transports: any[] = [];
if (process.env.NODE_ENV === 'production') {
  const host = process.env.REACT_APP_LOGGING_ENDPOINT_HOST;
  const path = process.env.REACT_APP_LOGGING_ENDPOINT_PATH;
  silent = !host;
  transports = [new winston.transports.Http({ host, path })];
} else {
  transports = [new winston.transports.Console({ stderrLevels: ['error'] })];
}

const _logger = winston.createLogger({
  level: process.env.REACT_APP_LOG_LEVEL || 'debug',
  format: winston.format.combine(
    filteringPassword(),
    labeling(),
    winston.format.timestamp({ format: _timestampFormat } as any), // 型定義があっていないのでanyで回避
    process.env.NODE_ENV === 'production' ? winston.format.json() : winston.format.simple()
  ),
  transports,
  exitOnError: false,
  silent,
});

const setDefaultMeta = (meta: Record<string, any>): void => {
  defaultMeta = { ...defaultMeta, ...meta };
};
const deleteDefaultMeta = (key: string): void => {
  delete defaultMeta[key];
};
const clearDefaultMeta = () => {
  defaultMeta = {};
};

const debug: LogMethod = (message, meta) => _logger.debug({ message, ...defaultMeta, ...meta });
const info: LogMethod = (message, meta) => _logger.info({ message, ...defaultMeta, ...meta });
const warn: LogMethod = (message, meta) => _logger.warn({ message, ...defaultMeta, ...meta });
const error: ErrorLogMethod = (messageOrError: string | Error, meta?: Record<string, any>) => {
  const infoObject: Record<string, any> = { ...defaultMeta, ...meta };
  if (typeof messageOrError === 'string') {
    infoObject.message = messageOrError;
  } else {
    infoObject.message = messageOrError.message;
    const stacktrace = (messageOrError.stack || '')
      .split('\n')
      .map(str => str.trim())
      .filter(Boolean);
    if (stacktrace.length > 0) {
      infoObject.stacktrace = stacktrace;
    }
  }
  _logger.error(infoObject);
};

const logger = {
  setDefaultMeta,
  deleteDefaultMeta,
  clearDefaultMeta,
  debug,
  info,
  warn,
  error,
};

export default logger;
