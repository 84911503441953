import React from 'react';
import { Link } from 'react-router-dom';

const SideMenu: React.FC = () => {
  return (
    <div className="nav">
      <a href={`${process.env.REACT_APP_RAILS_ADMIN_URL}`}>会社一覧</a>
      <a href={`${process.env.REACT_APP_RAILS_ADMIN_URL}/notices`}>お知らせ一覧</a>
      <Link to="/billing">請求処理</Link>
      <Link to="/holiday">祝祭日管理</Link>
      <Link to="/admin">Admin管理</Link>
      <Link to="/managerAdmin">ManagerAdmin管理</Link>
    </div>
  );
};

export default SideMenu;
