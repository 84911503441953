import React, { FunctionComponent } from 'react';
import SideMenu from '../components/shared/SideMenu';
import Notifications from '../components/shared/Notifications';
import useNotifications from '../hooks/useNotifications';

const SideMenuLayout: FunctionComponent = ({ children }) => {
  const notifications = useNotifications();

  return (
    <div className="d-flex m-0">
      <SideMenu />
      <div className="main">
        <Notifications notifications={notifications} />
        {children}
      </div>
    </div>
  );
};

export default SideMenuLayout;
