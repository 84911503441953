import useNotifications from 'hooks/useNotifications';
import React, { FunctionComponent, useCallback } from 'react';
import useAppStateDispatch from '../../../hooks/useAppStateDispatch';
import httpClient from '../../../libs/HTTPClient';
import Button from '../../form/Button';

const Billing: FunctionComponent = () => {
  const dispatch = useAppStateDispatch();

  const handerClick = async (state: string): Promise<void> => {
    const resp = await httpClient.post('/freees/access_token_check', { state });
    if (resp.status !== 201) {
      addNotificationErrors('Access Tokenの取得に失敗しました。');
    } else {
      if (resp.data?.redirectUri) {
        window.location.href = resp.data.redirectUri;
      } else {
        window.location.href = `/${state}`;
      }
    }
  };

  const addNotificationErrors = useCallback(
    (error: string): void => {
      dispatch({
        type: 'AddNotificationAction',
        payload: { type: 'error', message: error },
      });
    },
    [dispatch]
  );

  const deleteToken = async (): Promise<void> => {
    const resp = await httpClient.delete('/freees/token');
    if (resp.status !== 200) {
      addNotificationErrors('Access Tokenの削除に失敗しました。');
    }
  };

  return (
    <div className="container">
      <h1>請求処理</h1>
      <p>毎月第3営業日までに、前月分の新規取引先取込、請求書作成を行います</p>
      <div className="row mt-5">
        <div className="col-2 center-y">
          <Button
            class="btn btn-primary large fullwidth"
            onClick={(): Promise<void> => handerClick('partners')}
          >
            新規取引先取込画面
          </Button>
        </div>
        <div className="col center-y ml-2">
          <p>
            新しく有料プランに契約した取引先をfreeeに取込みます。
            ジョブカンワークフローで「ハイジ有料プラン契約取引先」
            として承認済みの取引先のみが取込みの対象となります
          </p>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-2 center-y">
          <Button
            class="btn btn-primary large fullwidth"
            onClick={(): Promise<void> => handerClick('contracts')}
          >
            請求情報取込
          </Button>
        </div>
        <div className="col center-y ml-2">
          <p>
            前月の課金対象人数から請求金額を計算し、freeeへ請求情報の
            取込を行います。対象となる取引先は、freeeへ取り込み済みである必要があります。
          </p>
        </div>
      </div>
      <p className="mt-5">
        ※請求書のメール送付は、freee側で行います。
        <br />
        また、請求書の再送付を行う際もfreee側でダウンロードし送付します。
        <br />
        ※取引先情報の変更は、freee側で行います。
      </p>

      <Button class="btn btn-primary" onClick={(): Promise<void> => deleteToken()}>
        freeeトークン削除
      </Button>
    </div>
  );
};

export default Billing;
