import React from 'react';
import AppContextProvider from './AppContextProvider';
import HTTPClient from '../libs/HTTPClient';
import Router from './Router';
import { HTTPClientResponse } from '../types/libs/HTTPClient';

HTTPClient.config.baseURL = process.env.REACT_APP_BFF_API_BASE_URL;
HTTPClient.config.headers = { 'Content-Type': 'application/json' };
HTTPClient.responseCallback = (res): HTTPClientResponse => {
  // tokenが無効だった場合に管理画面TOPへ遷移する
  if (res.status === 401) {
    localStorage.removeItem('AccessToken');
    window.location.href = `${process.env.REACT_APP_RAILS_ADMIN_URL}`;
  }
  return res;
};

function App(): React.ReactElement {
  return (
    <AppContextProvider>
      <Router />
    </AppContextProvider>
  );
}

export default App;
