import React, { FunctionComponent, useCallback, useEffect } from 'react';
import useAppStateDispatch from '../../../../hooks/useAppStateDispatch';
import httpClient from '../../../../libs/HTTPClient';

const Check: FunctionComponent = () => {
  const dispatch = useAppStateDispatch();
  const query: Array<string> | undefined = window.location?.search.slice(1).split('&');
  const params = query?.reduce((obj: Record<string, string>, param) => {
    const [key, value] = param.split('=');
    obj[key] = value;
    return obj;
  }, {});

  const addNotificationErrors = useCallback(
    (error: string): void => {
      dispatch({
        type: 'AddNotificationAction',
        payload: { type: 'error', message: error },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    (async (): Promise<void> => {
      const { code, state } = params;
      const resp = await httpClient.post('/freees/access_token', { code });

      if (resp.status === 201) {
        window.location.href = `/${state}`;
      } else {
        addNotificationErrors('Access Tokenの取得に失敗しました。');
      }
    })();
  }, [addNotificationErrors, params]);

  return <p>Code Check</p>;
};

export default Check;
