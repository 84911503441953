import {
  HTTPClient as HTTPClientType,
  HTTPClientConfig,
  HTTPClientMethodOption,
  HTTPClientRequestConfig,
  HTTPClientResponse,
} from '../types/libs/HTTPClient';
import Logger from './Logger';
import HTTPError from '../errors/HTTPError';

const logging = (message: string, meta?: Record<string, any>) => {
  try {
    Logger.info(message, { ...meta, label: 'HTTP' });
  } catch (e) {
    // ロガーが死んでいる可能性があるので何もしない
  }
};

class HTTPClient implements HTTPClientType {
  config: HTTPClientConfig = {};
  responseCallback: (res: HTTPClientResponse) => HTTPClientResponse = res => res;

  constructor(config?: HTTPClientConfig) {
    this.config = config || {};
  }

  private static createBodyChunk(body: any): string | FormData | undefined {
    if (!body) {
      return;
    }

    if (typeof body === 'string') {
      return body;
    }

    if (body instanceof FormData) {
      return body;
    }

    if (body instanceof URLSearchParams) {
      return body.toString();
    }

    return JSON.stringify(body);
  }

  private createURL(url: string, query?: Record<string, any>): string {
    const ref = /https?:\/\//.test(url) ? url : `${this.config.baseURL}${url}`;

    if (!query) {
      return ref;
    }

    const matches = ref.match(/^(?<path>[^?]+)(?<params>\?.*)?$/);
    if (!matches) {
      return ref;
    }

    const { path, params } = matches.groups || {};
    const searchParams = new URLSearchParams(params || '');
    Object.entries(query).forEach(([name, value]) => {
      searchParams.append(name, value);
    });

    return `${path}?${searchParams}`;
  }

  private request<T = any, R extends HTTPClientResponse = HTTPClientResponse<T>>(
    _url: string,
    _config?: HTTPClientRequestConfig
  ): Promise<R> {
    const { body: _body, query, ...config } = _config || {};
    const url = this.createURL(_url, query);
    const body = HTTPClient.createBodyChunk(_body);

    const meta: Record<string, any> = {};
    if (body) {
      meta.body = body instanceof FormData ? Object.fromEntries(body.entries()) : body;
    }
    if (config.headers) {
      meta.headers = config.headers;
    }

    logging(`Called ${config.method} ${url}`, meta);

    return fetch(url, {
      ...config,
      body,
      headers: { ...this.config.headers, ...config.headers },
    })
      .then(async res => {
        let data: any;
        try {
          data = await res.json();
        } catch (e) {
          data = res.bodyUsed ? `${res.body}` : null;
        }

        const response: R = {
          status: res.status,
          statusText: res.statusText,
          headers: res.headers,
          data,
        } as R;

        logging(`Completed ${res.status}`);

        this.responseCallback(response);
        return response;
      })
      .catch(e => {
        logging(`Completed with error. (${e.message})`);
        throw e;
      });
  }

  delete<T = any, R extends HTTPClientResponse = HTTPClientResponse<T>>(
    url: string,
    options?: HTTPClientMethodOption
  ): Promise<R> {
    return this.request(url, { ...options, method: 'DELETE' });
  }

  get<T = any, R extends HTTPClientResponse = HTTPClientResponse<T>>(
    url: string,
    options?: HTTPClientMethodOption
  ): Promise<R> {
    return this.request(url, { ...options, method: 'GET' });
  }

  patch<T = any, R extends HTTPClientResponse = HTTPClientResponse<T>>(
    url: string,
    data?: any,
    options?: HTTPClientMethodOption
  ): Promise<R> {
    return this.request(url, { ...options, body: data, method: 'PATCH' });
  }

  post<T = any, R extends HTTPClientResponse = HTTPClientResponse<T>>(
    url: string,
    data?: any,
    options?: HTTPClientMethodOption
  ): Promise<R> {
    return this.request(url, { ...options, body: data, method: 'POST' });
  }

  put<T = any, R extends HTTPClientResponse = HTTPClientResponse<T>>(
    url: string,
    data?: any,
    options?: HTTPClientMethodOption
  ): Promise<R> {
    return this.request(url, { ...options, body: data, method: 'PUT' });
  }
}

export const createHTTPClient = (config?: HTTPClientConfig): HTTPClient => new HTTPClient(config);

export default createHTTPClient();
