import React from 'react';
import { AppState } from '../types/contexts/AppContext';
import GlobalStateContext from '../contexts/GlobalStateContext';

const useAppState = (): AppState => {
  const context = React.useContext(GlobalStateContext);
  if (!context) {
    throw new Error('GlobalStateContext is not initialized.');
  }
  return context;
};

export default useAppState;
