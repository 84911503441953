import React, { FunctionComponent, useEffect, useState, ChangeEvent, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import TextInput from '../form/TextInput';
import SelectInput from '../form/SelectInput';
import httpClient from '../../libs/HTTPClient';
import Logger from '../../libs/Logger';
import useAppStateDispatch from '../../hooks/useAppStateDispatch';
import HygiError from '../../types/HygiError';

const PartnerLogs: FunctionComponent = () => {
  const { id } = useParams<Record<string, string>>();
  const dispatch = useAppStateDispatch();
  const history = useHistory();
  const [prefectures, setPrefectures] = useState<Record<number, string>>([]);
  const [partnerLog, setPartnerLog] = useState({
    id: id,
    companyId: 0,
    name: '',
    email: '',
    tel: '',
    zip: 0,
    prefectureId: 0,
    address1: '',
    address2: '',
  });

  const addNotificationErrors = useCallback(
    (errors: HygiError[]): void => {
      errors.forEach((error: HygiError): void => {
        dispatch({
          type: 'AddNotificationAction',
          payload: { type: 'error', message: error.detail },
        });
      });
    },
    [dispatch]
  );

  useEffect(() => {
    const getPrefectureOptions = async (): Promise<void> => {
      const resp = await httpClient.get('/prefectures/');
      if (resp.status !== 200) {
        addNotificationErrors(resp.data.error);
        Logger.error(resp.data.error);
      } else {
        setPrefectures(resp.data);
      }
    };
    getPrefectureOptions();
  }, [addNotificationErrors]);

  useEffect(() => {
    const getPartnerLog = async (): Promise<void> => {
      const resp = await httpClient.get(`/partner_logs/${id}`);
      if (resp.status !== 200) {
        addNotificationErrors(resp.data.errors);
        Logger.error(resp.data.errors);
        history.push('/billing');
      } else {
        setPartnerLog(resp.data);
      }
    };
    getPartnerLog();
  }, []);

  const changeName = (event: ChangeEvent<HTMLInputElement>): void => {
    partnerLog.name = event?.target?.value;
    setPartnerLog({ ...partnerLog });
  };

  const changeEmail = (event: ChangeEvent<HTMLInputElement>): void => {
    partnerLog.email = event?.target?.value;
    setPartnerLog({ ...partnerLog });
  };

  const changeTel = (event: ChangeEvent<HTMLInputElement>): void => {
    partnerLog.tel = event?.target?.value;
    setPartnerLog({ ...partnerLog });
  };

  const changeZip = (event: ChangeEvent<HTMLInputElement>): void => {
    partnerLog.zip = Number(event?.target?.value);
    setPartnerLog({ ...partnerLog });
  };

  const changePrefecture = (event: ChangeEvent<HTMLSelectElement>): void => {
    partnerLog.prefectureId = Number(event?.target?.value);
    setPartnerLog({ ...partnerLog });
  };

  const changeAddress1 = (event: ChangeEvent<HTMLInputElement>): void => {
    partnerLog.address1 = event?.target?.value;
    setPartnerLog({ ...partnerLog });
  };

  const changeAddress2 = (event: ChangeEvent<HTMLInputElement>): void => {
    partnerLog.address2 = event?.target?.value;
    setPartnerLog({ ...partnerLog });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const resp = await httpClient.patch(`/partner_logs/${id}`, { partnerLog });
    if (resp.status !== 201) {
      Logger.error(resp.data.errors);
      addNotificationErrors(resp.data.errors);
    } else {
      dispatch({
        type: 'AddNotificationAction',
        payload: { type: 'info', message: '更新しました' },
      });
    }
  };

  return (
    <div className="container">
      <h1>請求先情報画面</h1>
      <form onSubmit={onSubmit}>
        <TextInput
          name="name"
          type="text"
          class="fullwidth"
          required={true}
          label="請求先担当者名"
          value={partnerLog?.name}
          maxLength={100}
          onChange={changeName}
        />
        <TextInput
          name="email"
          type="email"
          class="fullwidth"
          label="請求先担当者メールアドレス"
          required={true}
          value={partnerLog?.email}
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          title="example@example.com"
          placeholder="example@example.com"
          onChange={changeEmail}
        />
        <TextInput
          name="tel"
          type="tel"
          class="fullwidth"
          label="請求先電話番号"
          required={true}
          value={partnerLog?.tel}
          pattern="^0\d{1,4}-\d{1,4}-\d{3,4}$"
          title="0000-0000-0000"
          placeholder="0000-0000-0000"
          onChange={changeTel}
        />
        <TextInput
          name="zip"
          type="text"
          class="fullwidth"
          label="郵便番号"
          required={true}
          value={partnerLog?.zip}
          pattern="^[0-9]{7}$"
          title="0000000"
          placeholder="0000000"
          onChange={changeZip}
        />
        <SelectInput
          name="prefecture_id"
          class="fullwidth"
          label="都道府県コード"
          required={true}
          options={prefectures}
          selected={partnerLog?.prefectureId}
          onChange={changePrefecture}
        />
        <TextInput
          name="address1"
          type="text"
          class="fullwidth"
          label="市区町村・番地"
          required={true}
          value={partnerLog?.address1}
          maxLength={255}
          onChange={changeAddress1}
        />
        <TextInput
          name="address2"
          type="text"
          class="fullwidth"
          label="建物名・部屋番号"
          value={partnerLog?.address2}
          maxLength={255}
          onChange={changeAddress2}
        />
        <input className="btn btn-primary" type="submit" value="更新" />
      </form>
    </div>
  );
};

export default PartnerLogs;
