import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import httpClient from 'libs/HTTPClient';
import Logger from 'libs/Logger';
import useAppStateDispatch from 'hooks/useAppStateDispatch';

const ManagerAdminIndex: FunctionComponent = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [managers, setManagers] = useState([]);
  const dispatch = useAppStateDispatch();
  const addNotification = useCallback(
    (type: 'error' | 'info', message: string): void => {
      dispatch({
        type: 'AddNotificationAction',
        payload: { type, message },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const changeEmail = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event?.target?.value);
  };

  const createManager = async (): Promise<void> => {
    if (!email) {
      setError('メールアドレスを入力してください');
      return;
    }
    const resp = await httpClient.post('/managers', { email: email });
    if (resp.status !== 201) {
      switch (resp.data.errors[0].title) {
        case 'email duplicate':
          setError('メールアドレスはすでに存在します');
          break;
        default:
          setError('メールアドレスを正しく入力してください');
      }
      Logger.error(resp.data.errors[0].detail);
    } else {
      setError('');
      setEmail('');
      addNotification('info', `${email}が追加されました`);
      setManagers(resp.data);
    }
  };

  const deleteManager = (id: number, email: string) => async (): Promise<void> => {
    const resp = await httpClient.delete(`/managers/${id}`);
    if (resp.status !== 200) {
      addNotification('error', 'メールアドレスは削除できなかった');
      Logger.error(resp.data.errors[0].detail);
    } else {
      addNotification('info', `${email}が削除されました`);
      setManagers(resp.data);
    }
  };

  useEffect(() => {
    getManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getManagers = async (): Promise<void> => {
    const resp = await httpClient.get('/managers');
    if (resp.status !== 200) {
      addNotification('error', resp.data.errors);
      Logger.error(resp.data.errors);
    } else {
      setManagers(resp.data);
    }
  };

  return (
    <div className="container">
      <table className="table table-border">
        <thead>
          <tr className="primary">
            <th>Email</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input type="text" className="fullwidth" value={email} onChange={changeEmail} />
              {error && <p className="text-danger">{error}</p>}
            </td>
            <td>
              <button className="btn btn-primary" onClick={createManager}>
                登録
              </button>
            </td>
          </tr>
          {managers.map(({ id, email }, index) => {
            return (
              <tr key={index}>
                <td>{email}</td>
                <td>
                  <button className="btn btn-danger" onClick={deleteManager(id, email)}>
                    削除
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ManagerAdminIndex;
