import React, { FunctionComponent, ChangeEvent } from 'react';

interface Props {
  label?: string;
  name: string;
  required?: boolean;
  type: 'text' | 'password' | 'email' | 'number' | 'tel';
  value?: string | number;
  placeholder?: string;
  error?: string;
  class?: string;
  pattern?: string;
  title?: string;
  maxLength?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TextInput: FunctionComponent<Props> = props => {
  return (
    <div className="form-inline row">
      {props.label && (
        <div className="col-3">
          <label htmlFor={props.name}>{props.label}</label>
        </div>
      )}
      <div className={props.label ? 'col-9' : 'col'}>
        <input
          type={props.type}
          required={props.required}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          className={props.class}
          pattern={props.pattern}
          title={props.title}
          maxLength={props.maxLength}
          onChange={props.onChange}
        />
        {props.error && <p className="form-error mt-1">{props.error}</p>}
      </div>
    </div>
  );
};

export default TextInput;
