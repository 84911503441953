import dayjs from 'dayjs';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import httpClient from '../../../../libs/HTTPClient';
import Logger from '../../../../libs/Logger';
import './Partners.scss';
import useAppStateDispatch from '../../../../hooks/useAppStateDispatch';
import HygiError from '../../../../types/HygiError';
import { JobCanStatus } from '../../../../enums/jobcan_status';

type PartnerType = {
  companyId: number;
  companyName: string;
  jobcanStatus: number;
  createdAt: string;
  planName: string;
  tel: string;
  name: string;
  email: string;
  zip: number;
  prefecture: string;
  address1: string;
  address2: string;
};

const getJobcanStatusText = (status: number) => {
  const jobcanStatus: any = {
    0: '下書',
    1: '進行',
    2: '完',
    3: '却',
    4: '取り消',
    5: '差し戻',
    6: '完了後取消',
    99: '申請なし',
  };

  return jobcanStatus[status];
};

const Partners: FunctionComponent = () => {
  const dispatch = useAppStateDispatch();
  const [partners, setPartners] = useState<PartnerType[]>([]);

  useEffect(() => {
    (async (): Promise<void> => {
      await updatePartners();
      await getPartners();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePartners = async (): Promise<void> => {
    const resp = await httpClient.patch('/partners');
    if (resp.status !== 200) {
      addNotificationErrors(resp.data.errors);
      Logger.error(resp.data.errors);
    }
  };

  const accessTokenCheck = async (): Promise<boolean> => {
    const resp = await httpClient.post('/freees/access_token_check', { state: 'contracts' });
    if (resp.status !== 201) {
      addNotificationErrors([
        {
          title: 'Access Tokenの取得に失敗しました。',
          detail: 'Access Tokenの取得に失敗しました。',
          status: 500,
        },
      ]);
      return false;
    } else if (resp.data?.redirectUri) {
      window.location.href = resp.data.redirectUri;
      return false;
    }
    return true;
  };

  const addPartnerToFreee = async (): Promise<void> => {
    const result = await accessTokenCheck();
    if (!result) return;

    const resp = await httpClient.post('/partners');
    if (resp.status !== 201) {
      addNotificationErrors(resp.data.errors);
      Logger.error(resp.data.errors);
    } else {
      dispatch({
        type: 'AddNotificationAction',
        payload: { type: 'info', message: '取引先を取込ました。' },
      });
    }
  };

  const getPartners = async (params = {}): Promise<void> => {
    const date = dayjs()
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY/MM/DD');

    const resp = await httpClient.get('/partners');
    if (resp.status !== 200) {
      addNotificationErrors(resp.data.errors);
      Logger.error(resp.data.errors);
    } else {
      setPartners(resp.data);
    }
  };

  const addNotificationErrors = useCallback(
    (errors: HygiError[]): void => {
      errors.forEach((error: HygiError): void => {
        dispatch({
          type: 'AddNotificationAction',
          payload: { type: 'error', message: error.detail },
        });
      });
    },
    [dispatch]
  );

  return (
    <div className="container">
      <h1>取引先情報取り込み</h1>
      <p>
        新規取引先の取り込みを行います。ジョブカンで申請・承認が終わっている取引先のみが取引の対応となります。
      </p>
      <div className="row mt-5">
        <div className="col-2 center-y">
          <button className="btn btn-primary large fullwidth" onClick={() => addPartnerToFreee()}>
            取引先取り込み実行
          </button>
        </div>
      </div>
      <div className="partners">
        <div className="header">
          <div className="row">
            <p className="company-id">会社ID</p>
            <p className="company-name">会社名</p>
            <p className="status">ステータス</p>
            <p className="created-at">申込み日</p>
            <p className="plan-name">申込みプラン名</p>
          </div>
          <div className="row">
            <p className="tel">請求先電話番号</p>
            <p className="name">請求先担当者名</p>
            <p className="email">請求先メールアドレス</p>
          </div>
          <div className="row mb-0">
            <p className="address mb-0">請求先住所</p>
          </div>
        </div>
        <div className="contents">
          {partners.map((partner, index) => (
            <PartnerRow key={index} {...partner} />
          ))}
        </div>
      </div>
    </div>
  );
};

const PartnerRow: FunctionComponent<PartnerType> = partner => {
  return (
    <div className={partner.jobcanStatus === JobCanStatus.Done ? 'done' : ''}>
      <div className="row">
        <p className="company-id pl-1">{partner.companyId}</p>
        <p className="company-name">{partner.companyName}</p>
        <p className="status">{getJobcanStatusText(partner.jobcanStatus)}</p>
        <p className="created-at">{dayjs(partner.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p>
        <p className="course-name">{partner.planName}</p>
      </div>
      <div className="row">
        <p className="tel">{partner.tel}</p>
        <p className="name">{partner.name}</p>
        <p className="email">{partner.email}</p>
      </div>
      <div className="row mb-0">
        <p className="address mb-0">
          〒{String(partner.zip).replace(/^(\d{3})(\d{4})$/, '$1-$2')} {partner.prefecture}
          {partner.address1}
          {partner.address2}
        </p>
      </div>
    </div>
  );
};

export default Partners;
