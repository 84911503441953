import React from 'react';
import { AppStateDispatch } from '../types/contexts/AppContext';
import GlobalDispatchContext from '../contexts/GlobalDispatchContext';

const useAppStateDispatch = (): AppStateDispatch => {
  const context = React.useContext(GlobalDispatchContext);
  if (!context) {
    throw new Error('GlobalDispatchContext is not initialized.');
  }
  return context;
};

export default useAppStateDispatch;
