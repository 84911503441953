import React, { FunctionComponent } from 'react';

interface Props {
  children: string | Array<string|JSX.Element>;
  class?: string;
  onClick?: any;
  disabled?: boolean;
}

const Button: FunctionComponent<Props> = props => {
  return (
    <button className={props.class} onClick={props.onClick} disabled={props.disabled}>{props.children}</button>
  );
}

export default Button;