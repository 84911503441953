export enum JobCanStatus {
  Draft = 0,
  Processing = 1,
  Done = 2,
  Rejected = 3,
  Cancelled = 4,
  Pending = 4,
  Deleted = 6,
  UnApplied = 99,
}
