import React, { FunctionComponent, ReactElement } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

export interface Props {
  component: FunctionComponent<RouteComponentProps>;
  layout: FunctionComponent;
  path: string;
}

const RouterWrapper: FunctionComponent<Props> = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props): ReactElement => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouterWrapper;
