import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import dayjs from 'dayjs';
import httpClient from '../../libs/HTTPClient';
import Logger from '../../libs/Logger';
import Pagination, { PaginationProps } from '../shared/Pagination';

const Holiday = (): JSX.Element => {
  const [holidays, setHolidays] = useState([]);
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [dateError, setDateError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [pagination, setPagination] = useState<PaginationProps>({ totalPages: 1, page: 1 });

  const getHoliday = async (params = {}): Promise<void> => {
    const resp = await httpClient.get('/holidays', { query: params });
    if (resp.status !== 200) {
      Logger.error(resp.data.error);
    } else {
      setHolidays(Object.values(resp.data.data));
      setPagination(resp.data);
    }
  };

  const onPageChange = useCallback(async (page: number, perPage: number): Promise<void> => {
    getHoliday({ page, perPage });
  }, []);

  const changeDate = (e: ChangeEvent<HTMLInputElement>): void => {
    setDate(e.target.value);
  };

  const changeDescription = (e: ChangeEvent<HTMLInputElement>): void => {
    setDescription(e.target.value);
  };

  const checkDateDuplicate = (): boolean => {
    return !holidays.find(holiday => dayjs(holiday['date']).format('YYYY-MM-DD') === date);
  };

  const isValidForm = (): boolean => {
    let hasError = false;
    if (!date) {
      setDateError('年月日を入力してください。');
      hasError = true;
    } else if (!checkDateDuplicate()) {
      setDateError('年月日が重複します。');
      hasError = true;
    } else {
      setDateError('');
    }
    if (!description) {
      setDescriptionError('説明は50文字で入力してください。');
      hasError = true;
    } else {
      setDescriptionError('');
    }
    return !hasError;
  };

  const createHoliday = async (): Promise<void> => {
    if (!isValidForm()) return;

    const postParams = {
      date: date,
      description: description.trim(),
    };

    const resp = await httpClient.post('/holidays', postParams);

    if (resp.status !== 201) {
      Logger.error(resp.data.error);
    } else {
      setDate('');
      setDescription('');
      setHolidays(Object.values(resp.data));
    }
  };

  const deleteHoliday = async (id: number): Promise<void> => {
    const resp = await httpClient.delete(`/holidays/${id}`, {});
    if (resp.status !== 200) {
      Logger.error(resp.data.error);
    } else {
      setHolidays(Object.values(resp.data));
    }
  };

  const holidayRows = (): JSX.Element[] => {
    return holidays.map(
      (holiday: { id: number; date: Date; description: string }, index: number) => {
        return (
          <tr key={index}>
            <td>{dayjs(holiday.date).format('YYYY年M月D日')}</td>
            <td>{holiday.description}</td>
            <td>
              <button
                className="btn btn-danger"
                onClick={(): Promise<void> => deleteHoliday(holiday.id)}
              >
                削除
              </button>
            </td>
          </tr>
        );
      }
    );
  };

  return (
    <div className="Holiday container">
      <h1>祝祭日管理</h1>
      <table className="table table-border">
        <thead>
          <tr className="primary">
            <th>年月日</th>
            <th>説明</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input type="date" value={date} onChange={changeDate} />
              {dateError && <p className="text-danger">{dateError}</p>}
            </td>
            <td>
              <input
                type="text"
                className="mr-1"
                value={description}
                onChange={changeDescription}
              />
              {descriptionError && <p className="text-danger">{descriptionError}</p>}
            </td>
            <td>
              <button className="btn btn-primary" onClick={createHoliday}>
                登録
              </button>
            </td>
          </tr>
          {holidayRows()}
        </tbody>
      </table>
      <Pagination
        totalPages={pagination.totalPages}
        page={pagination.page}
        onChange={onPageChange}
      />
    </div>
  );
};

export default Holiday;
