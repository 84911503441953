import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import SelectInput from '../form/SelectInput';

const PAGINATION_WIDTH = 5;
const PAGINATION_DEFAULT_PER_PAGE = 10;

export type PaginationProps = {
  totalPages: number;
  page: number;
  onChange?: (page: number, perPage: number) => void;
};

const SideMenu: FunctionComponent<PaginationProps> = props => {
  const [page, setPage] = useState(props.page);
  const [perPage, setPerPage] = useState(PAGINATION_DEFAULT_PER_PAGE);
  let start: number, width: number;

  if (PAGINATION_WIDTH > props.totalPages) {
    width = props.totalPages;
    start = 1;
  } else {
    width = PAGINATION_WIDTH;
    start = Math.max(page - (Math.ceil(width / 2) - 1), 1);
  }

  useEffect(() => {
    props.onChange && props.onChange(page, perPage);
  }, [page, perPage]);

  const changePage = (page: number): void => {
    if (page <= 0 || page > props.totalPages) return;
    setPage(page);
  };

  const perPageOptions = { 10: '10', 20: '20', 50: '50' };

  const changePerPage = (event: ChangeEvent<HTMLSelectElement>): void => {
    setPage(1);
    setPerPage(Number(event?.target?.value));
  };

  return (
    <div className="pagination">
      <SelectInput
        name="perPage"
        inline={true}
        options={perPageOptions}
        selected={perPage}
        onChange={changePerPage}
      />
      <button className="btn mx-1" onClick={(): void => changePage(1)}>
        &laquo;
      </button>
      <button
        className={`btn mx-1 ${page === 1 ? 'disabled' : ''}`}
        onClick={(): void => changePage(page - 1)}
      >
        &lt;
      </button>
      {[...Array(width)].map((_, index) => {
        const toPage = start + index;
        return (
          <button
            key={index}
            className={`btn mx-1 ${page === toPage ? 'active' : ''}`}
            onClick={(): void => changePage(toPage)}
          >
            {start + index}
          </button>
        );
      })}
      <button
        className={`btn mx-1 ${page === props.totalPages ? 'disabled' : ''}`}
        onClick={(): void => changePage(page + 1)}
      >
        &gt;
      </button>
      <button className="btn mx-1" onClick={(): void => changePage(props.totalPages)}>
        &raquo;
      </button>
    </div>
  );
};

export default SideMenu;
