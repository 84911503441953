import React, { FunctionComponent } from 'react';
import { BrowserRouter, Switch, Route, RouteProps, Redirect } from 'react-router-dom';
import Holiday from './pages/Holiday';
import StyleSample from './StyleSample';
import SideMenuLayout from '../layouts/SideMenuLayout';
import RouteWithAccessTokenCheck from '../layouts/WithAccessTokenCheck';
import Billing from './pages/Billing';
import PartnerLogs from './pages/PartnerLogs';
import Partners from './pages/Billing/Partners';
import Contracts from './pages/Billing/Contracts';
import FreeeCheck from './pages/Billing/Freee/check';
import AdminIndex from './pages/Admin';
import ManagerAdminIndex from './pages/ManagerAdmin';

const Router: FunctionComponent<RouteProps> = props => {
  return (
    <BrowserRouter>
      <Switch>
        <RouteWithAccessTokenCheck path="/billing" component={Billing} layout={SideMenuLayout} />
        <RouteWithAccessTokenCheck path="/holiday" component={Holiday} layout={SideMenuLayout} />
        <RouteWithAccessTokenCheck
          path="/partner_logs/:id"
          component={PartnerLogs}
          layout={SideMenuLayout}
        />
        <RouteWithAccessTokenCheck path="/partners" component={Partners} layout={SideMenuLayout} />
        <RouteWithAccessTokenCheck
          path="/contracts"
          component={Contracts}
          layout={SideMenuLayout}
        />
        <RouteWithAccessTokenCheck
          path="/freee/check"
          component={FreeeCheck}
          layout={SideMenuLayout}
        />
        <RouteWithAccessTokenCheck path="/admin" component={AdminIndex} layout={SideMenuLayout} />
        <RouteWithAccessTokenCheck
          path="/managerAdmin"
          component={ManagerAdminIndex}
          layout={SideMenuLayout}
        />
        <Route path="/style" component={StyleSample} />
        <Redirect from="/" to="billing" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
