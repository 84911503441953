import React, { FunctionComponent, ChangeEvent } from 'react';

interface Props {
  label?: string;
  name: string;
  required?: boolean;
  selected?: number;
  inline?: boolean;
  options: Record<number, string>;
  error?: string;
  class?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const SelectInput: FunctionComponent<Props> = props => {
  return (
    <div className={props.inline ? 'inline' : 'form-inline row'}>
      {props.label && (
        <div className="col-3">
          <label htmlFor={props.name}>{props.label}</label>
        </div>
      )}
      <div className={props.label ? 'col-9' : 'col'}>
        <select
          name={props.name}
          required={props.required}
          className={props.class}
          onChange={props.onChange}
          value={props.selected}
        >
          {Object.entries(props.options).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>
        {props.error && <p className="form-error mt-1">{props.error}</p>}
      </div>
    </div>
  );
};

export default SelectInput;
